/* Styles to Agent Signup Pricing Page */
.pricing-main {
  padding: 30px;
}
/* Mobile/Desktop Display */
.pricing-desktop {
  display: block;
}
.pricing-mobile {
  display: none;
}
@media only screen and (max-width: 900px) {
  .pricing-desktop {
    display: none;
  }
  .pricing-mobile {
    display: block;
  }
}
.pricing-container {
  background-color: #f5f7fc66;
  border-radius: 8px;
  box-shadow: 0px 4px 15px -1px #11085034;
  padding: 10px 20px 20px;
}
/* Pricing Header */
.pricing-header {
  margin: auto;
  margin-top: 90px;
  text-align: center;
  width: 70%;
}
.pricing-header h1 {
  color: #c85933;
  font-size: 1.8rem;
  margin-bottom: 40px;
}
.pricing-header h2 {
  color: rgb(22, 23, 39);
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 20px;
}
.pricing-header p {
  color: rgb(22, 23, 39);
}
/* Styles to Desktop Pricing Table */
.pricing-container table {
  border: none;
}
.pricing-th h2 {
  font-size: 2rem;
  margin-bottom: 5px;
}
.pricing-th h2,
.pricing-price {
  color: #c85933;
}
.pricing-price {
  font-size: 1.2rem;
  margin-right: 10px;
}
.pricing-conditions {
  color: rgba(62, 40, 40, 0.75);
  font-weight: 300;
}
.pricing-description {
  height: 70px;
  color: rgba(40, 43, 62, 0.78);
}
.pricing-th div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding-left: 10px;
  text-align: left;
  width: 100%;
  margin: auto;
}
.pricing-th hr {
  border: 0.5px solid rgba(40, 43, 62, 0.392);
  width: 100%;
}
.pricing-container tbody tr:hover {
  background-color: #f2f2f2;
}
.pricing-container tbody td {
  width: 20%;
}
.pricing-container td,
.pricing-container th {
  padding: 20px;
}
@media only screen and (max-width: 1200px) {
  .pricing-th div {
    height: 80px;
    justify-content: center;
    padding-left: 0px;
  }
  .pricing-price {
    font-size: 1rem;
    margin-right: 5px;
    width: auto;
  }
}
.check-container {
  display: flex;
  justify-content: center;
}
.pricing-container tbody img {
  width: 30px;
}
.pricing-signup-btn {
  border: 1px solid #c85933;
  border-radius: 10px;
  background-color: #c85933;
  color: white;
  font-weight: bold;
  padding: 10px 15px;
  width: 100%;
}
.pricing-signup-btn:hover {
  cursor: pointer;
}
.pricing-container tbody .no-hover:hover {
  background-color: #f5f7fc66;
}
.pricing-container {
  position: relative;
}
.pricing-banner {
  position: absolute;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  background-color: #c85933;
  color: white;
  font-size: 1.3rem;
  font-weight: bold;
  left: 0px;
  top: 70px;
  padding: 10px 20px;
}
@media only screen and (max-width: 1000px) {
  .pricing-banner {
    font-size: 1.1rem;
  }
}
/* Pricing Footer */
.pricing-footer {
  margin-top: 30px;
  margin-bottom: 50px;
  text-align: center;
}
/* Styles to Mobile Pricing Cards */
.pricing-signup-btn.mobile {
  margin-top: 20px;
}
.pricing-th.mobile h2 {
  margin-bottom: 5px;
}
.pricing-th .mobile-div {
  height: 50px;
}
.pricing-description.mobile {
  height: auto;
  margin-top: 30px;
}
.pricing-mobile .pricing-container {
  margin-top: 30px;
}
/* Styles to Pricing Modal */
.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.236);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-container {
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  width: 50%;
}
.modal-close-btn {
  background-color: #fff;
  border: 1px solid #c85933;
  border-radius: 5px;
  color: #c85933;
  font-size: 1rem;
  font-weight: bold;
  position: absolute;
  right: 15px;
  top: 15px;
  padding: 5px 10px !important;
}
.modal-close-btn:hover {
  cursor: pointer;
}
.pricing-modal h3 {
  font-size: 1.8rem;
  margin-top: 0;
  padding-top: 45px;
}
.pricing-modal form {
  margin: auto;
  margin-bottom: 50px;
  width: 80%;
}
.pricing-modal label {
  color: rgba(13, 23, 43, 0.892);
  display: block;
  margin-top: 20px;
  margin-bottom: 5px;
}
.pricing-modal span {
  color: red;
}
.pricing-modal input {
  border: 1px solid rgba(128, 128, 128, 0.449);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
}
.submit-btn {
  background-color: #c85933;
  color: #fff;
  font-weight: bold;
  margin-top: 30px;
}
.submit-btn:hover {
  cursor: pointer;
}
@media only screen and (max-width: 650px) {
  .modal-container {
    width: 95%;
  }
}
.modal-container .success-message {
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.success-close-btn {
  border: 1px solid #c85933;
  border-radius: 10px;
  background-color: #c85933;
  color: #fff;
  font-weight: bold;
  margin: auto;
  margin-top: 30px;
  padding: 10px 0px;
  width: 80%;
}
.success-close-btn:hover {
  cursor: pointer;
}
