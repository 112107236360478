.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  gap: 10px;
  border-color: #dde3ec !important;
}
.result {
  height: 1rem;
  padding: 8px;
}
