.otp-box {
  padding: 50px;
  border-radius: 5px;
  border: 1px solid #dde3ec;
}
.otp-form-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}
.otp-validation-page {
  display: flex;
  flex-direction: column;
}
.otp-validation-page input {
  width: 100%;
  padding: 13px 22px;
  border-radius: 5px;
  border: 1px solid #dde3ec;
  background: #ffffff;
  font-weight: 500;
  font-size: 16px;
  color: #07074d;
  outline: none;
  resize: none;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.reset-btn {
  text-align: center;
  width: 100%;
  font-size: 16px;
  border-radius: 5px;
  padding: 14px 25px;
  border: none;
  font-weight: 500;
  background-color: #c85933;
  color: white;
  cursor: pointer;
  margin-top: 25px;
}
