.admin {
  background-color: #dadce2b7;
  min-height: 100vh;
}
.admin header {
  min-height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 80px 10px 40px;
  background-color: #f5f5f5;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
}
@media (max-width: 768px) {
  .admin-logo-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
  }
}
.admin-logo {
  height: 80px;
}
.admin-hamburg {
  display: none;
}
@media (max-width: 768px) {
  .admin-hamburg {
    display: block;
  }
  .admin-hamburg:hover {
    cursor: pointer;
  }
  .admin header {
    padding: 10px 40px;
  }
}
.admin header div:hover {
  cursor: pointer;
}
.admin header p:after {
  content: "\276F";
  width: 20px;
  height: 20px;
  margin-left: 5px;
  margin-top: 5px;
  position: absolute;
  transform-origin: center;
  transform: rotateZ(90deg);
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
  color: rgb(54, 54, 54);
}
.nav-open p:after {
  transform: rotateZ(270deg) !important;
  margin-top: -7px !important;
}
.admin-menu {
  display: none;
  position: absolute;
  background-color: #f5f5f5;
}
.admin-menu ul {
  padding: 0;
}
.admin-menu ul li {
  padding: 30px;
  border: 1px solid rgb(200, 89, 51);
}
.admin-menu ul li:hover {
  color: rgb(200, 89, 51);
}
.admin-menu.open {
  display: block;
}
/* Sidebar Styles */
.admin-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: #f5f5f5;
  width: 180px;
  z-index: 1;
  transition: all 0.5s ease-in-out;
}
.admin-sidebar ul {
  margin-top: 120px;
  padding-left: 10px;
}
.admin-sidebar ul li {
  padding: 20px 0;
  color: rgb(54, 54, 54);
  font-weight: bold;
}
.admin-sidebar ul li:hover {
  cursor: pointer;
}
.admin-sidebar .highlight {
  color: rgb(200, 89, 51);
}
@media (max-width: 900px) {
  .admin-sidebar ul {
    margin-left: 40px;
  }
}
@media (max-width: 768px) {
  .admin-sidebar {
    left: -200px;
  }
  .admin-sidebar.open {
    left: 0px;
  }
}
/* Main Container Styles */
.admin main {
  margin-left: 200px;
  padding-top: 120px;
  padding-bottom: 50px;

  overflow-x: auto;
}
@media (max-width: 768px) {
  .admin main {
    margin-left: 0px;
  }
}
/* Dashboard Styles */
.admin-table-group {
  margin-bottom: 40px;
}
.admin-notices {
  border: 1px solid rgba(0, 0, 0, 0.202);
  border-collapse: collapse;
}
.admin-notices thead {
  background-color: rgb(200, 89, 51);
  color: #fff;
}
.admin-notices.busca thead {
  background-color: #343459;
}
.admin-notices th,
.admin-notices td {
  padding: 10px 20px;
}
.admin-notices tbody tr {
  background: #ffffffca;
}
.admin-notices tr:nth-of-type(even) > * {
  background: #edf2f4;
}
.admin-action {
  color: rgb(200, 89, 51);
  font-weight: bold;
}
.busca .admin-action {
  color: #343459;
  font-weight: bold;
}
.admin-action:hover {
  cursor: pointer;
  text-decoration: underline;
}
/* Dashboard Modal Styles */
.admin-account-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(128, 128, 128, 0.434);

  display: flex;
  justify-content: center;
  align-items: center;
}
.admin-modal-content {
  margin-left: 200px;
  margin-top: 120px;

  background-color: #fff;
  border-radius: 10px;
  min-height: 200px;
  max-height: 450px;
  overflow-y: auto;
  max-width: 500px;
  padding: 10px 30px;
  padding-bottom: 30px;
}
@media (max-width: 768px) {
  .admin-modal-content {
    margin-left: 0px;
  }
}
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-header span:hover {
  cursor: pointer;
}
/* Manage Users Page */
.admin-search-form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 50px;
}
.admin-search-input {
  width: 50%;
  padding: 15px 22px;
  border-radius: 5px;
  border: 1px solid #dde3ec;
  background: #ffffff;
  font-weight: 500;
  font-size: 16px;
  color: #07074d;
  outline: none;
  resize: none;
  box-sizing: border-box;
  margin-right: 10px;
}
.admin-search-input::placeholder {
  color: #536387;
  font-family: "Playfair Display";
}
.admin-search-input:focus {
  border-color: #c85933;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}
.admin-search-btn {
  cursor: pointer;
  color: white;
  background-color: #c85933;
  font-family: "Open Sans";
  font-weight: bold;
  border-radius: 0.5em;
  border: none;
  font-size: 12px;
  text-decoration: none;
  padding: 1.2em 2em;
}
.admin-form-select {
  width: 20%;
  padding: 15px 22px;
  border-radius: 5px;
  border: 1px solid #dde3ec;
  background: #ffffff;
  font-size: 16px;
  color: #536387;
  outline: none;
  resize: none;
}
.admin-form-select::placeholder {
  color: #536387;
  font-family: "Playfair Display";
}
.manage-users-table {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 95%;
  overflow-x: auto;
}
.manage-users-table h2 {
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  color: #2f2f2f;
}
@media (max-width: 768px) {
  .manage-users-table {
    margin-left: 20px;
  }
}
/* Styles to Pagination */
.admin-pagination-container::before,
.admin-pagination-container::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
.admin-pagination-container {
  width: 100%;
}
.admin-pagination-container .pagination {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 0.6rem;
  box-shadow: 0 0.8rem 2rem rgba(#5a6181, 0.05);
  box-sizing: border-box;
  font-size: 62.5%;
  overflow-y: auto;
  letter-spacing: 0.6px;
  margin-top: 40px;
  line-height: 1.4;
  -webkit-user-select: none;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  list-style-type: none;
}
.admin-pagination-container .page-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3px;
  font-size: 1.2rem;
  cursor: pointer;
  margin-top: 1rem;
  /* color: #c85933e1; */
}
.admin-pagination-container .page-item {
  min-width: 40px;
  height: 40px;
  border-radius: 0.4rem;
}
.admin-pagination-container .page-item:hover {
  color: #c85933;
}
.admin-pagination-container .page-item.active {
  color: #ffffff;
  background: #c85933;
  font-weight: 600;
  border: 1px solid #c85933;
}
/* Edit Agent Styles */
.admin-account-modal-edit {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(128, 128, 128, 0.434);

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 999;
}
.admin-modal-content-edit {
  background-color: #fff;
  border-radius: 10px;
  width: 90%;
  height: 90%;
  overflow: auto;
  padding: 10px 30px;
  padding-bottom: 30px;
}
/* Busca Styles */
.busca .admin-search-btn,
.busca .admin-notices thead,
.busca .admin-pagination-container .page-item.active,
.busca .agent-btn {
  background-color: #343459;
}
.busca .admin-action {
  color: #343459;
}
.download-csv-btn {
  background: #c85933;
  color: #fff;
  font-weight: 600;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  margin-top: 20px;
}
.download-csv-btn:hover {
  cursor: pointer;
}
