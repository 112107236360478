/* Style top navigation bar */
.header {
  background-color: #c85933;
  height: 6.5rem;
  opacity: 0.9;
  position: relative;
  z-index: 4;
}
.header a {
  float: right;
  color: white;
  text-align: right;
  font-size: 15px;
  font-weight: bold;
  font-family: "Playfair Display";
  padding: 2.5rem 25px;
}
.logo {
  float: left;
  position: absolute;
  top: 10px;
  left: 40px;
  width: 90px;
}
ul {
  list-style-type: none;
  margin: 0px;
}

/* Hamburger Styling */
.hamburger {
  display: none;
  float: right;
}
.menuIcon {
  display: none;
  float: right;
}
.closeIcon {
  display: none;
  float: right;
}
.decoration-none {
  text-decoration: none;
}
@media (max-width: 920px) {
  .header a {
    font-size: 12px;
    padding: 2.5rem 20px;
  }
}
@media (max-width: 900px) {
  * {
    margin: 0;
    padding: 0;
    box-sizing: 0;
  }
  .header a {
    font-size: 15px;
    padding: 2.5rem 25px;
  }
  .menuItem {
    display: block;
    margin: auto;
    text-align: center;
    font-size: 10rem;
    color: white;
  }
  .hamburger {
    display: block;
    position: absolute;
    z-index: 6;
    top: 1rem;
    right: 1rem;
    padding: 4px;
    border: #c85933 solid 1px;
    background-color: #c85933;
    opacity: 0.9;
    cursor: pointer;
  }
  .menu {
    position: fixed;
    transform: translateY(-100%);
    transition: transform 0.2s;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background: #c85933;
    color: white;
    list-style: none;
    padding-top: 4rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
  .showMenu {
    transform: translateY(0);
    z-index: 9999999;
  }
  .closeIcon {
    display: none;
    color: white;
    background-color: #c85933;
    opacity: 0.9;
  }
  .menuIcon {
    display: block;
    background-color: #c85933;
    opacity: 0.9;
    color: white;
  }
}
/* Fix: Drop down menu positioning */
.front {
  z-index: 6;
}
