hr {
  border-top: 1px solid rgba(0, 0, 0, 0.271);
  width: 80%;
  margin: auto;
}
.footer-section {
  /* width: 80%; */
  max-width: 1080px;
  margin: auto;
}
.footer a {
  /* text-align: center; */
  color: #c85933;
  font-size: 15px;
  font-weight: bold;
  font-family: "Playfair Display";
  margin-top: 0.7rem;
  white-space: nowrap;
}
.footer a:hover {
  text-decoration: underline;
}
.footer-menu {
  display: flex;
  justify-content: space-around;
  /* max-width: 500px; */
  margin: auto;
  margin-top: 10px;
  padding-left: 5em;
  padding-right: 5em;
  margin-bottom: 30px;
}
.footer-menu div h2 {
  font-size: 18px;
  font-weight: 600;
  color: rgb(68, 64, 64);
}
@media (max-width: 826px) {
  .footer-menu div h2 {
    text-align: center;
    margin-top: 30px;
  }
}
.footer-menu div a {
  display: block;
}
@media (max-width: 826px) {
  .footer-menu {
    display: block;
  }
  .footer a {
    display: block;
    width: 100%;
    text-align: center;
  }
  .brain-jar-link a {
    margin-top: 0 !important;
  }
}
.footer-title {
  text-align: center;
  font-family: "Open Sans";
  font-size: 15px;
}
.footer-legal {
  text-align: left;
  font-family: "Open Sans";
  font-size: 12px;
  /* padding-left: 5rem;
  padding-right: 5rem; */
}
.footer-image {
  display: block;
  margin: auto;
  width: 100%;
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

.brain-jar-link {
  font-family: "Open Sans";
  font-size: 10px;
  text-align: center;
}
.brain-jar-link a {
  font-family: "Open Sans";
  color: blue;
  font-size: 10px;
  text-align: center;
  margin-top: -40em;
}
@media (max-width: 500px) {
  .footer-image {
    display: block;
    margin: auto;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.text-shadow-sm {
  color: #c85933 !important;
  text-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.516);
}

.text-center {
  text-align: center;
}

.jason {
  color: #c85933;
  text-align: center;
  font-family: "Open Sans";
  margin-top: 30px;

  font-weight: bold;
}
