.pw-text,
.span {
  color: #c85933;
  font-weight: 700;
  white-space: nowrap;
}
.password-reset-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.password-input input {
  background: #fff;
  border: 1px solid #dde3ec;
  border-radius: 5px;
  box-sizing: border-box;
  color: #07074d;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  padding: 13px 22px;
  resize: none;
  width: 100%;
}
.password-btn {
  background-color: #c85933;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  margin-top: 25px;
  padding: 14px 25px;
  text-align: center;
  width: 100%;
}
.span {
  display: flex;
  align-items: center;
}
.signup {
  color: #3b3b3b;
  text-decoration: none;
}
.password-form {
  padding: 50px;
  border-radius: 5px;
  border: 1px solid #dde3ec;
}
