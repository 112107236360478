.agent-search-result-card {
  box-shadow: 0px 2px 12px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 30%;
  margin-bottom: 70px;
}
.agent-card-top {
  background-color: #c85933;
  border-radius: 10px 10px 0 0;
  height: 10px;
  width: 100%;
}
.agent-card-content {
  padding: 15px;
}
.agent-search-result-card img {
  border-radius: 10px;
  height: 240px;
  object-fit: cover;
  object-position: center center;
  width: 98%;
}
.agent-search-result-card h3 {
  color: black;
  font-size: 1.4rem;
  text-align: left;
  margin: 0;
  padding-top: 10px;
}
.agent-search-result-card p {
  margin: 0;
}
.agent-card-btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 5px;
}
.agent-card-btn {
  cursor: pointer;
  color: white;
  background-color: #c85933;
  font-family: "Open Sans";
  font-weight: bold;
  border-radius: 0.5em;
  border: none;
  font-size: 12px;
  text-decoration: none;
  padding: 1.2em 2em;
}
@media only screen and (max-width: 830px) {
  .agent-search-result-card {
    width: 45%;
  }
}
@media only screen and (max-width: 600px) {
  .agent-search-results-container {
    justify-content: center !important;
  }
  .agent-search-result-card {
    width: 300px;
  }
}
