.privacy-policy {
  color: rgb(56, 56, 56);
  max-width: 800px;
  margin: auto;
  padding: 40px;
}
.privacy-policy h1 {
  text-align: center;
  margin-bottom: 70px;
}
.privacy-policy h2 {
  font-size: 1.3rem;
  margin: 30px 0;
}
.privacy-policy p {
  margin: 10px 0;
  line-height: 1.3rem;
}
.privacy-policy li {
  line-height: 2rem;
}
.privacy-policy a {
  color: blue;
}
