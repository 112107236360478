/* Search Section Styling */
.search-container {
  background-image: url("../../assets/hero.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 15rem;
  object-fit: cover;
  margin-top: 30px;
}
h3 {
  font-size: 20px;
  color: #c85933;
  text-align: center;
  padding-top: 2em;
}
.search-button-container {
  text-align: center;
  margin: 0 auto;
  display: flex;
}
.search-btn-home {
  cursor: pointer;
  color: white;
  background-color: #c85933;
  font-family: "Open Sans";
  font-weight: bold;
  border-radius: 0.5em;
  border: none;
  font-size: 12px;
  margin: auto;
  margin-top: 0.5em;
  text-decoration: none;
  padding: 1em 2em;
}
/* Styles to Home Cards */
.home-card-container {
  margin-top: 50px;
  margin-bottom: 50px;
}
.home-card-container a {
  text-decoration: none;
  color: black;
}
.home-card {
  background-color: rgb(255, 255, 255);
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.205) 0px 2px 4px 1.5px;
  width: 80%;
  margin: auto;
  margin-bottom: 20px;
  padding: 15px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.home-card img {
  width: 100%;
  height: auto;
}
.home-card h2 {
  margin-bottom: 15px;
}
.home-card p {
  color: rgb(36, 36, 36);
  letter-spacing: 1.1;
  font-size: 1rem;
  font-family: "Open Sans";
  margin-bottom: 15px;
}
.home-card button {
  background-color: #fff;
  border: 1px solid #c85933;
  border-radius: 5px;
  color: #c85933;
  font-size: 1rem;
  font-weight: bold;
  font-family: "Open Sans";
  margin-bottom: 25px;
  padding: 10px 20px;
  text-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.347);
  transition: all 0.2s ease-in-out;
}
.home-card:hover {
  transform: scale(1.05);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 6px 1.5px;
}
.home-card:hover button {
  cursor: pointer;
  background-color: #c85933;
  color: #fff;
}
@media only screen and (min-width: 900px) {
  .home-card {
    height: 475px;
  }
}
@media only screen and (min-width: 515px) {
  /*Small smartphones to tablet [515px ->]*/
  .home-card img {
    width: 400px;
  }
}
@media only screen and (min-width: 600px) {
  /*Small smartphones to tablet [600pxpx ->]*/
  .home-card {
    display: flex;
    padding: 30px 25px;
    max-width: 650px;
  }
  .home-card img {
    width: 250px;
    height: auto;
  }
  .home-card p {
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 900px) {
  /*Small smartphones to tablet [900px ->]*/
  .home-card-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 900px;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .home-card {
    display: block;
    margin: 0 10px 20px 20px;
    max-width: 330px;
  }
  .home-card.card-2 img {
    margin-bottom: 5px;
  }
  .home-card.card-2 p {
    margin-bottom: 35px;
  }
  .home-card img {
    width: 100%;
    height: auto;
  }
}
